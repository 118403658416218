<template>
	<div class="wrap__content">
		<div class="container">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['ReportingManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<HeaderBack :title="getReportTitle" :subtitle="$t('reports.name.px-1-mini')" />
							<div class="card__content--body">
								<form method="POST" enctype="multipart/form-data" @submit.prevent="onSubmit">
									<AnimalWorldUserName />
									<div class="reporting-line">
										<div class="row">
											<div class="col-xl-4">
												<div class="reporting__title">
													{{ $t('inputs.region') }}
												</div>
											</div>
											<div class="col-xl-5">
												<region
													:name="'region_id'"
													:required="true"
													:key="render_region"
													:errors="$v.formApp.region_id"
													:disabled="false"
													:modelValue="formApp.region_id"
													:userBind="userRegions"
													:placeholder="$t('placeholder.choose')"
													v-on:update:modelValue="formApp.region_id = $event"
												>
												</region>
											</div>
										</div>
									</div>
									<div class="reporting-line">
										<div class="row">
											<div class="col-xl-4">
												<div class="reporting__title">
													{{ $t('inputs.pond') }}
												</div>
											</div>
											<div class="col-xl-5">
												<pond
													:required="true"
													:key="render_pond"
													:errors="$v.formApp.farm_id"
													:disabled="isNotEditable"
													:userBind="userPond"
													:modelValue="formApp.farm_id"
													:name="'farm_id'"
													:placeholder="$t('placeholder.choose')"
													v-on:update:modelValue="formApp.farm_id = $event"
												></pond>
											</div>
										</div>
									</div>

									<kato
										:required="true"
										:hidden="true"
										:disabled="statusPage == 'show'"
										:errors="$v.formApp.kato_id"
										:modelValue="formApp.kato_id"
										:name="'kato_id'"
										:placeholder="$t('placeholder.choose')"
										v-on:update:modelValue="formApp.kato_id = $event"
									>
									</kato>

									<div class="reporting-line">
										<div class="row">
											<div class="col-xl-4">
												<div class="reporting__title">{{ $t('placeholder.select_month') }}</div>
											</div>
											<div class="col-xl-5">
												<SelectMonth
													v-model="month"
													:disabled="month_input_disabled"
													:required="true"
													:errors="$v.month"
													:placeholder="$t('placeholder.select_month')"
													:input-years="true"
													ref="q"
												/>
											</div>
										</div>

										<div class="input-required" v-if="$v.month && !$v.month.required">
											{{ $t('system_message.required_message') }}
										</div>
									</div>

									<hr class="reporting__hr" />

									<div v-if="tradeApp && tradeApp.length" ref="tradeApp">
										<div
											v-for="(typeCatch, typeCatchInd) in tradeApp"
											:key="typeCatchInd.keyd"
											class="reporting-line"
										>
											<div class="row mb-4">
												<div class="col-xl-4">
													<div class="reporting__title">{{ typeCatch.typeCatch }}</div>
												</div>
												<div class="col-xl-7">
													<div class="row total">
														<div class="col-lg-6">
															<TextInput
																:name="'commercial_fishing[0][total_catch_quota]'"
																:title="$t('headers.quatos_to_catch')"
																:type="'number'"
																:required="true"
																:disabled="true"
																:placeholder="$t('headers.quatos_to_catch')"
																:modelValue="getFishesSum(typeCatchInd, 'quota')"
															></TextInput>
														</div>
														<div class="col-lg-6">
															<TextInput
																:name="'commercial_fishing[0][total_actually_caught]'"
																:title="$t('headers.fact_catch')"
																:type="'number'"
																:required="true"
																:disabled="true"
																:placeholder="$t('headers.fact_catch')"
																:modelValue="getFishesSum(typeCatchInd, 'caught')"
															></TextInput>
														</div>
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col-xl-4">
													<div class="reporting__title">В том числе по видам рыб</div>
												</div>
												<div class="col-xl-7">
													<template v-if="typeCatch.fishes">
														<div
															v-for="(item, index) in typeCatch.fishes"
															:key="index"
															class="items"
														>
															<div class="row mb-2">
																<div class="col-lg-4">
																	<fish
																		class="mb-2"
																		:title="'Рыба'"
																		:placeholder="$t('headers.select_fish')"
																		:name="'[' + index + '][weight]'"
																		:disabled="isNotEditable"
																		:modelValue="item.id"
																		:variant="'def-input'"
																	></fish>
																</div>
																<div class="col-lg-4">
																	<TextInput
																		:name="`[catch_quota]`"
																		:title="$t('headers.quatos_to_catch')"
																		:required="true"
																		:disabled="isNotEditable"
																		:placeholder="$t('placeholder.quantity')"
																		:modelValue="item.quota"
																		v-on:blur="updateQuota(typeCatchInd, index, $event)"
																	>
																	</TextInput>
																</div>
																<div class="col-lg-4">
																	<TextInput
																		:name="`[actually_caught]`"
																		:title="$t('headers.fact_catch')"
																		:required="true"
																		:disabled="true"
																		:placeholder="$t('placeholder.quantity')"
																		:modelValue="item.caught"
																	>
																	</TextInput>
																</div>
															</div>
														</div>
													</template>
												</div>
											</div>
										</div>
									</div>
									<div v-else-if="tradeApp && !tradeApp.length">
										На данный квартал по водоему нет записей в журнале
									</div>

									<hr class="reporting__hr" />

									<ReportManageFormButtons
										:edit="!isNotEditable"
										:sign-status="sign_status"
										:report="'1-rh-mini'"
									/>

									<ReportManageErrorModal :message="message" :errorMessage="errorMessage" />
									<ReportManageModal @accept="saveElement" />
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment'

import { api, apiSecond } from '@/boot/axios'

import TextInput from '@/components/components/Inputs/TextInput.vue'
import Region from '@/components/components/Inputs/region'
import SelectMonth from '@/components/UI/SelectMonth.vue'
import Pond from '@/components/components/Inputs/pond'
import Kato from '@/components/components/Inputs/kato'
import { required } from 'vuelidate/lib/validators'
import Fish from '@/components/components/Inputs/fish'
import HeaderBack from '@/components/UI/HeaderBack.vue'
import AnimalWorldUserName from '@/components/reporting-management/AnimalWorldUserName.vue'
import ReportManageModal from '@/components/reporting-management/ReportManageModal.vue'
import ReportManageFormButtons from '@/components/reporting-management/ReportManageFormButtons.vue'
import ReportManageErrorModal from '@/components/reporting-management/ReportManageErrorModal.vue'

export default {
	name: 'ReportPage1Mini',
	components: {
		HeaderBack,
		AnimalWorldUserName,
		Kato,
		Pond,
		Region,
		TextInput,
		SelectMonth,
		Fish,
		ReportManageModal,
		ReportManageFormButtons,
		ReportManageErrorModal,
	},
	props: {
		statusPage: {
			type: String,
			default: 'create',
		},
	},
	validations: {
		month: {
			required,
		},
		formApp: {},
	},
	data() {
		return {
			application: null,
			userRegions: null,
			id: null,
			tradeApp: null,
			sign_status: false,
			render_pond: 0,
			render_region: 0,
			render_district: 0,
			applicationsUser: null,
			month_input_disabled: false,
			dataEcp: null,
			filters: {
				date_from: null,
				date_to: null,
			},
			month: null,
			formApp: {
				region_id: null,
				farm_id: null,
				district_id: null,
				kato_id: null,
				amateur_catch_quota: null,
				amateur_actually_caught: null,
				meliorative_catch_quota: null,
				meliorative_actually_caught: null,
			},
			errorMessage: {
				status: null,
				messages: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},
		}
	},
	watch: {
		'$store.state.userObject': {
			immediate: true,
			handler: function () {
				this.apiGetUserRegions()
			},
		},
		'formApp.region_id': function () {
			this.render_pond++
			this.render_region++
			this.getApplications()
		},
		'formApp.district_id': function () {
			this.render_district++
		},
		sign_status: function (val) {
			if (val === true) {
				this.$router.push('/ru/account/reporting/1-rh-mini')
			}
		},
		month() {
			this.getApplications()
		},
		'formApp.farm_id': function () {
			this.getApplications()
		},
	},
	computed: {
		isNotEditable() {
			return this.statusPage == 'show'
		},
		getReportTitle() {
			if (this.statusPage == 'show') return this.$t('reporting.title')
			else if (this.statusPage == 'edit') return this.$t('headers.report_editing')
			return this.$t('headers.report_adding')
		},
		userPond() {
			return this.applicationsUser?.pond || []
		},
		getFishesSum() {
			return (typeInd, key) => {
				return (this.tradeApp || [])[typeInd]?.fishes.reduce((summ, elem) => {
					summ += Number(elem[key])
					return summ
				}, 0)
			}
		},
		isFullFilled() {
			return this.statusPage === 'edit' || this.statusPage === 'show'
		},
	},
	methods: {
		saveElement() {
			if (this.$configFile.active) return this.sendEcpKey(this.$configFile.xml)
			const websocket = new WebSocket('wss://127.0.0.1:13579/')
			var ready = null
			websocket.onopen = () => {
				ready = true
				handle()
			}
			websocket.onclose = (e) => {
				if (!e.wasClean) {
					if (this.$i18n.locale == 'kz')
						alert('NCYLayer бағдарламасы іске қосылғандығына көз жеткізіңіз')
					if (this.$i18n.locale == 'ru') alert('Убедитесь, что программа NCALayer запущена')
					if (this.$i18n.locale == 'en') alert('Make sure that the NCALayer program is running')

					//setErrormodal(true);
					setTimeout(() => {
						//setErrormodal(false);
					}, 5000)
				}
			}
			function handle() {
				if (!ready) {
					alert('Убедитесь, что программа NCALayer запущена')
				} else {
					const data = {
						module: 'kz.gov.pki.knca.commonUtils',
						method: 'signXml',
						args: [
							'PKCS12',
							'SIGNATURE',
							'<login><sessionid>caacda70-fd36-45ed-8d94-45a88890f83a</sessionid></login>',
							'',
							'',
						],
					}
					websocket.send(JSON.stringify(data))
				}
			}
			websocket.onmessage = (e) => {
				const data1 = JSON.parse(e.data)
				if (data1.responseObject) {
					this.sendEcpKey(data1.responseObject)
				}
			}
		},
		sendEcpKey(ecpString) {
			const formData = new FormData()
			formData.append('xml', ecpString)
			document.querySelector('.preloader').classList.add('active')
			apiSecond
				.post(`/api/v2/miniReportOne/sign/${this.$route.params.id}`, formData)
				.then((response) => {
					if (response.status == 200) {
						this.$router.push({
							path: '/' + this.$i18n.locale + '/account/reporting/1-rh-mini',
						})
					}
				})
				.catch((error) => {
					this.checkError(error)
				})
				.finally(() => {
					document.querySelector('.preloader').classList.remove('active')
				})
		},
		updateQuota(typeInd, fishInd, value) {
			this.tradeApp = this.tradeApp?.map((type, tInd) => {
				if (tInd === typeInd) {
					return {
						typeCatchId: type.typeCatchId,
						typeCatch: type.typeCatch,
						keyd: type.keyd,
						fishes: type.fishes.map((fish, fInd) => {
							if (fInd === fishInd) {
								return {
									...fish,
									quota: value,
								}
							} else return fish
						}),
					}
				} else return type
			})
		},
		getMonthInterval() {
			const year = new Date().getFullYear()
			const start = new Date(year, this.month, 1)
			const end = new Date(year, this.month + 1, 0)

			return [moment(start).format('YYYY-MM-DD'), moment(end).format('YYYY-MM-DD')]
		},
		saveElementReport() {
			const formData = new FormData()

			// if (this.statusPage === 'edit') {
			// 	formData.append('_method', 'PUT')
			// }

			formData.append('region_id', this.formApp.region_id)
			formData.append('reservoir_id', this.formApp.farm_id)

			if (this.month || this.month === 0) {
				const [start_date, end_date] = this.getMonthInterval()

				formData.append('reportDate', start_date || '2023-01-01')
				formData.append('surrender_date_start', start_date || '2023-01-01')
				formData.append('surrender_date_end', end_date || '2023-01-01')
			}

			this.tradeApp?.forEach((trade, tradeInd) => {
				formData.append(`type_catches[${tradeInd}][type_catch_id]`, trade.typeCatchId)
				formData.append(
					`type_catches[${tradeInd}][type_catch_quota]`,
					this.getFishesSum(tradeInd, 'quota'),
				)
				formData.append(
					`type_catches[${tradeInd}][type_catch_caught]`,
					this.getFishesSum(tradeInd, 'caught'),
				)

				trade.fishes?.forEach((fish, fishInd) => {
					formData.append(`type_catches[${tradeInd}][fishes][${fishInd}][fish_id]`, fish.id)
					formData.append(`type_catches[${tradeInd}][fishes][${fishInd}][quota]`, fish.quota)
					formData.append(`type_catches[${tradeInd}][fishes][${fishInd}][caught]`, fish.caught)
				})
			})

			if (this.$route.path.includes('edit')) {
				apiSecond
					.post(`/v2/miniReportOne/edit/${this.$route.params.id}`, formData)
					.then(() => {
						this.$router.push({
							path: '/' + this.$i18n.locale + '/account/reporting/1-rh-mini',
						})
					})
					.catch((error) => {
						this.checkError(error)
					})
					.finally(() => {
						document.querySelector('.preloader').classList.remove('active')
					})
			} else {
				apiSecond
					.post('/v2/miniReportOne/create', formData)
					.then(() => {
						this.$router.push({
							path: '/' + this.$i18n.locale + '/account/reporting/1-rh-mini',
						})
					})
					.catch((error) => {
						this.checkError(error)
					})
					.finally(() => {
						document.querySelector('.preloader').classList.remove('active')
					})
			}
		},
		apiGetUserRegions() {
			if (this.$store.state.userObject && this.$store.state.userObject.regions) {
				let array = []
				this.$store.state.userObject.regions.find((element) => {
					array.push(element.region_id)
				})
				this.userRegions = array
			}
		},
		apiGetUser() {
			api
				.get('/user/region')
				.then((response) => {
					if (response.data) {
						this.applicationsUser = response.data
					}
				})
				.catch((error) => {
					this.checkError(error)
				})
		},
		onSubmit() {
			this.$v.$touch()
			if (!this.$v.$invalid) {
				document.querySelector('.preloader').classList.add('active')
				this.saveElementReport()
			}
		},
		getApplications() {
			if (!this.formApp.farm_id || !this.formApp.region_id || typeof this.month !== 'number') return

			const [start_date, end_date] = this.getMonthInterval()

			this.tradeApp = null
			api
				.get('trade/user/from-to', {
					params: {
						surrender_date_start: start_date,
						surrender_date_end: end_date,
						region_id: this.formApp.region_id,
						reservoir_id: this.formApp.farm_id,
					},
				})
				.then((response) => {
					if (response.data) {
						const tradeApp = response.data.data

						if (this.statusPage !== 'create' && this.application) {
							this.application.typeCatches?.forEach((typeCatch) => {
								const trade = tradeApp.find(
									(trade) => trade.typeCatchId === typeCatch.type_catch_id,
								)
								if (trade) {
									trade.fishes.forEach((fish) => {
										fish.quota =
											typeCatch.fishes.find((f) => f.fish_id === fish.id)?.quota || fish.quota
									})
								}
							})
						}

						this.tradeApp = tradeApp
					}
				})
		},
		formatDate(date) {
			if (!date) return ''
			let str = ''
			const dateArr = date.split('.').reverse()
			str = dateArr[0] + '-' + dateArr[1] + '-' + dateArr[2]
			return str
		},
		async showDetailManagment() {
			if (this.statusPage == 'show' || this.statusPage == 'edit') {
				try {
					const { data } = await apiSecond.get(`/v2/miniReportOne/show/${this.$route.params.id}`)
					this.application = data
					const datastart = data.report_date
					if (datastart) {
						const date = new Date(datastart)
						this.month = date.getMonth()
						this.month_input_disabled = true
					}
					this.id = data.id
					this.formApp.region_id = data.region_id
					this.formApp.farm_id = data.reservoir_id
					// this.formApp.amateur_catch_quota = data.amateur_meliorative_fishing.amateur_catch_quota
					// this.formApp.amateur_actually_caught =
					// 	data.amateur_meliorative_fishing.amateur_actually_caught
					// this.formApp.meliorative_catch_quota =
					// 	data.amateur_meliorative_fishing.meliorative_catch_quota
					// this.formApp.meliorative_actually_caught =
					// 	data.amateur_meliorative_fishing.meliorative_actually_caught
					setTimeout(() => {
						const date__inputs = document.querySelectorAll('.date__input')
						if (date__inputs[0]) {
							date__inputs[0].value = this.formatDate(data.surrender_date_start)
						}
						if (date__inputs[1]) {
							date__inputs[0][1].value = this.formatDate(data.surrender_date_end)
						}
					}, 100)
				} catch (error) {
					console.log('error', error)
					this.checkError(error)
				}
			}
		},
		checkStatusSign() {
			if (!this.$route.params.id) return

			apiSecond
				.get('/v2/miniReportOne/index')
				.then((response) => {
					if (response.data) {
						const post_status = response.data.data.find((e) => {
							return e.id == this.$route.params.id
						})

						if (post_status.status) {
							if (post_status.status === 'Подписан') {
								this.sign_status = true
							}
						}
					}
				})
				.catch((error) => {
					this.checkError(error)
				})
		},
		checkError(error) {
			if (error?.response?.status > 500) {
				this.errorMessage.status = 500
				this.errorMessage.text = this.$t('system_message.500')
			} else if (error?.response?.status == 401) {
				this.errorMessage.status = 401
				this.errorMessage.text = error?.response?.data?.message
				this.$router.push('/' + this.$i18n.locale + '/login')
			} else {
				this.errorMessage.status = 422
				this.errorMessage.text = error?.response?.data?.message
			}
			this.$modal.show('ReportManageErrorModal')
		},
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
	beforeMount() {
		this.showDetailManagment()
		this.getApplications()
		this.apiGetUser()
		this.checkStatusSign()
	},
}
</script>

<style scoped></style>

<template>
	<div class="input-wrapper">
		<div v-if="title" class="title">
			{{ title }}
			<span v-if="subtitle">
				<br />
				{{ subtitle }}
			</span>
		</div>
		<div class="input-body" :class="{ 'input-body--error': errors && errors.$error }">
			<multiselect
				v-model="getValue"
				:options="getMonthsList"
				:track-by="'id'"
				:label="'label'"
				:max-height="200"
				:max="3"
				:preserve-search="true"
				:required="required"
				:multiple="multiple"
				:close-on-select="!multiple"
				:disabled="disabled"
				class="multiselect__check"
			>
				<template slot="singleLabel" slot-scope="{ option }">{{ option.label }}</template>
				<template slot="noResult">{{ $t('components.not_found') }}</template>
				<template slot="noOptions">{{ $t('components.not_found') }}</template>
				<template slot="placeholder">{{ $t('placeholder.choose') }}</template>
			</multiselect>
			<input type="hidden" :name="name" :value="value" />
		</div>
		<div class="input-required" v-if="errors && !errors.required">
			{{ $t('system_message.required_message') }}
		</div>
	</div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
	name: 'SelectMonth',
	components: {
		Multiselect,
	},
	props: {
		value: {
			type: null,
			default: null,
		},
		errors: {
			type: Object,
			default: null,
		},
		title: {
			type: String,
			default: null,
		},
		subtitle: {
			type: String,
			default: null,
		},
		required: {
			type: Boolean,
			default: false,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		name: {
			type: String,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['input'],
	computed: {
		getValue: {
			get() {
				if (this.multiple) {
					return this.value.map((id) => this.getMonthsList.find((m) => m.id === id))
				} else {
					return this.getMonthsList.find((m) => m.id === this.value)
				}
			},
			set(value) {
				if (this.multiple) {
					this.$emit(
						'input',
						value.map((v) => v.id),
					)
				} else {
					this.$emit('input', value.id)
				}
			},
		},
		getMonthsList() {
			return new Array(12).fill(null).map((_, ind) => {
				return {
					id: ind,
					label: this.$t(`consts.monthsNames.${ind}`),
				}
			})
		},
	},
}
</script>

<style>
.title {
	font-weight: 600;
	font-size: 12px;
	color: #0a091d;
	margin-bottom: 8px;
	line-height: 1.2;
}

.title span {
	color: #708295;
}

.input-body .multiselect__tags {
	padding-left: 20px;
	border: 1px solid #0a091d;
	border-radius: 5px;
	background: #ffffff;
	font-weight: 400;
	font-size: 14px;
	color: #0a091d;
	line-height: 1.2;
	width: 100%;
}

.q .input-body {
	position: relative;
}

/* .input-body .multiselect__content-wrapper {
	width: 261px;
} */

.q .multiselect__tags {
	display: flex;
	align-items: center;
}

.q .multiselect__tags {
	padding-left: 0px;
	padding-right: 0px;
	padding: 0;
}

.q .multiselect__placeholder {
	padding-left: 10px;
}

.q input[type='number'] {
	width: 70px;
	padding-right: 10px;
}

.q .input-body {
	display: flex;
	border: 1px solid #d3e7fb;
	align-items: center;
	padding: 0 0px;
}

.input-body .multiselect__tags {
	border: 1px solid #d3e7fb !important;
}

.q .input-body .multiselect__tags {
	border: transparent !important;
}

.input-body .multiselect__select {
	display: none;
}

.input-body .multiselect--disabled .multiselect__tags {
	border: 1px solid #d3e7fb;
}

.input-body .multiselect--disabled .multiselect__placeholder {
	margin-bottom: 0;
}

.input-body .multiselect__single {
	margin-bottom: 0;
}

.input-body--error.input-body .multiselect__tags {
	border-color: #f3574d;
}

.input-body--error + .input-required {
	display: block;
}
</style>
